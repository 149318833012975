import React, { useContext } from 'react'
import Card from './Card'
import ThemeContext from '../../context/ThemeContext';



const Details = ({details}) => {
    const {darkMode} = useContext(ThemeContext)
    const companyList = {
        name: "Name",
        ticker:"Ticker",
        marketCapitalization: "Market Cap",
        country:"Country",
        finnhubIndustry:"Industry",
        ipo:"IPO",
        exchange:"Exchange",
    };

    const convertMilliontoBillion = (number) => {
        return (number / 1000).toFixed(2);
    };


  return (
    <Card>
        <ul className={`w-full h-full flex flex-col justify-between divide-y-1 ${darkMode ? "divide-gray-800" : null}`}>
            {Object.keys(companyList).map((item) => {
                return <li key={item} className='flex-1 flex justify-between items-center'>
                    <span>{companyList[item]}</span>
                    <span>{item === "marketCapitalization"  ? `${convertMilliontoBillion(details[item])}B`: details[item]}</span>
                </li>;
            })}
        </ul>
    </Card>
  )
}

export default Details