import React, { useContext } from "react";
import StockContext from "../../context/StockContext";
import ThemeContext from "../../context/ThemeContext";

const SearchResults = ({ results }) => {
  const { darkMode } = useContext(ThemeContext);
  const { setStockSymbol } = useContext(StockContext);

  return (
    <ul
      className={`absolute top-12 border-2 w-full rounded-md h-64 overflow-y-scroll ${
        darkMode
          ? "bg-gray-800 border-gray-700 custom-scrollbar custom-scrollbar-dark"
          : "bg-white border-neutral-200 custom-scrollbar"
      }`}
    >
      {results.map((item) => (
        <li
          key={item['1. symbol']}
          className={`cursor-pointer p-4 m-2 flex items-center justify-between rounded-md ${
            darkMode
              ? "hover:bg-indigo-700 text-white"
              : "hover:bg-indigo-200 text-black"
          } transition duration-300`}
          onClick={() => setStockSymbol(item['1. symbol'])}
        >
          <span className="text-sm font-normal">{item['1. symbol']}</span>
          <span className="text-sm font-normal">{item['2. name']}</span>
        </li>
      ))}
    </ul>
  );
};

export default SearchResults;
