import React from 'react';

const NewsCard = ({ newsItem, isMain }) => {
  const cardClassName = isMain ? "col-span-2" : ""; // Adicionado estilo responsivo

  return (
    <div className={`p-4 mb-4 ${cardClassName} shadow-md bg-white rounded-lg overflow-hidden`}>
      <img
        src={newsItem.banner_image}
        alt="News Banner"
        className="mb-2 w-full h-64 object-cover object-center"
      />
      <h2 className="text-xl font-semibold mb-2">{newsItem.title}</h2>
      <p className="text-gray-600">{newsItem.summary}</p>
    </div>
  );
};

export default NewsCard;
