import React, { useState, useEffect } from 'react';

import { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext';
import Card from './Card';
import NavBar from '../NavBar/NavBar';
import "./style.css";
import heroImage from './hero.png';
import MarketStatus from './MarketStatus';

const HomePage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { darkMode, setDarkMode  } = useContext(ThemeContext);


  useEffect(() => {
    setDarkMode(false);
  }, [setDarkMode]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Card>
      <div className='shadow-2xl bg-gray-300 flex items-center place-content-between animated-shadow'>
        <h1 className={`text-4xl ml-3 sm:text-2xl md:text-4xl lg:text-5xl xl:text-6xl ${darkMode ? "text-white" : "text-gradient-purple"} font-bold text-shadow-md`}>
          SEEDWEAL
        </h1>
        <div>
          <NavBar />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-4">
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <p className="text-4xl md:text-7xl mx-4 md:mx-10 uppercase font-bold text-gradient-purple ">
            Empower your financial journey <br />
            with tools for tracking and taking <br />
            control of investments
          </p>
        </div>
        {!isMobile && (
          <div className="w-full md:w-1/2">
            <img src={heroImage} alt="homepage image"></img>
          </div>
        )}
      </div>
      
      <div className="mt-10">
        <p className="text-center font-bold text-4xl md:text-5xl text-gradient-purple">MARKET STATUS</p>
        <MarketStatus />
      </div>
    </Card>
  );
};

export default HomePage;
