import React, { useState, useEffect } from "react";
import ApexChart from "react-apexcharts";
import NavBar from "../NavBar/NavBar";
import ThemeContext from "../../context/ThemeContext";
import { useContext } from "react";
import PageTheme from "../StockInfo/PageTheme";
import "./style.css";
import hero from "./cloud.png";

const Portfolio = () => {
  const { darkMode } = useContext(ThemeContext);

  return (
    <>
      <div
        className={` min-h-screen  ${
          darkMode ? "bg-gray-900 " : "bg-gray-200"
        }`}
      >
        <div
          className={`shadow-2xl bg-gray-300 animated-shadow ${
            darkMode ? "bg-gray-900 " : "bg-gray-200"
          }`}
        >
          <NavBar />
        </div>

        <div className="p-10">
          <PageTheme />
        </div>
        <div id="atum">
          <h1 className={` ${darkMode ? "text-orange-50 " : " "}`}>
            Soon you will be able to track your investments here..
          </h1>
        </div>
        <div className="p-20 flex justify-center ">
          <img src={hero} alt="teste" />
        </div>
      </div>
    </>
  );
};

export default Portfolio;
