import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './style.css'; // Importe o arquivo CSS

const MarketStatusTicker = () => {
  const [marketData, setMarketData] = useState([]);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_API_KEY1;
    const fetchMarketData = async () => {
      try {
        const response = await axios.get(`https://www.alphavantage.co/query?function=MARKET_STATUS&entitlement=delayed&apikey=${apiKey}`);
        const data = response.data.markets;

        const filteredRegions = ['United States', 'Canada', 'United Kingdom', 'Portugal', 'France', 'Germany', 'Spain', 'Japan', 'Hong Kong'];
        const filteredData = data.filter((market) => filteredRegions.includes(market.region) && market.region !== 'Global');

        setMarketData(filteredData);
      } catch (error) {
        console.error('Error fetching market data:', error);
      }
    };

    fetchMarketData();
  }, []);

  return (
    <div className="table-container ">
      <table className="table">
        <thead>
          <tr>
            <th className='uppercase'>Region</th>
            <th className='uppercase'>Status</th>
          </tr>
        </thead>
        <tbody>
          {marketData.map((market, index) => (
            <tr key={index} className={market.current_status === 'open' ? 'open' : 'closed'}>
              <td className='text-gradient-purple uppercase font-bold'>{market.region}</td>
              <td className={market.current_status === 'open' ? 'text-gradient-green' : 'text-gradient-red'}>
  {market.current_status === 'open' ? 'OPEN' : 'CLOSED'}
</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarketStatusTicker;






