import React from 'react'
import ThemeContext from '../../context/ThemeContext'
import { useContext } from 'react'

const Card = ({ children }) => {
    const { darkMode } = useContext(ThemeContext);
    return (
      <div className={` min-h-screen
      ${darkMode ? "bg-gray-900 " : "bg-gray-200"
        }`}>
        {children}
      </div>
    )
  }
  
  export default Card