import React, { useEffect, useState, useContext } from 'react';
import NewsCard from './NewCards';
import StockContext from '../../context/StockContext'
import ThemeContext from '../../context/ThemeContext'

const NewsContainer = () => {
  const [newsData, setNewsData] = useState([]);
  const [visibleNews, setVisibleNews] = useState(6);
  const [loadMoreVisible, setLoadMoreVisible] = useState(true);
  const { stockSymbol } = useContext(StockContext);
  const { darkMode } = useContext(ThemeContext);
  useEffect(() => {
    const apiKey = process.env.REACT_APP_API_KEY1;
    console.log('stockSymbol:', stockSymbol);
    const apiUrl = `https://www.alphavantage.co/query?function=NEWS_SENTIMENT&tickers=${stockSymbol}&apikey=${apiKey}`;

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        setNewsData(data.feed);
      })
      .catch(error => {
        console.error('Error fetching news data:', error);
      });
  }, [stockSymbol]);

  const loadMoreNews = () => {
    const nextVisibleNews = visibleNews + 7;
    setVisibleNews(nextVisibleNews);

    if (nextVisibleNews >= newsData.length) {
      setLoadMoreVisible(false);
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className={`text-3xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-black'}`}>Latest News {stockSymbol}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {newsData ? newsData.slice(0, visibleNews).map((newsItem, index) => (
          <NewsCard key={index} newsItem={newsItem} />
        )) : []}
      </div>
      {loadMoreVisible && (
        <button
          className="bg-blue-500 text-white px-4 py-2 mt-4 rounded p-10"
          onClick={loadMoreNews}
        >
          Carregar mais notícias
        </button>
      )}
    </div>
  );
};

export default NewsContainer;