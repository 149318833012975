import React from 'react'
import Card from './Card'

const Overview = ({ symbol, price, change, changePercent, currency }) => {
    return (
        <Card>
            <span className='absolute left-4 top-4 text-neutral-400 text-lg xl:text-xl 2x1:text-2x1'>
                {symbol}
            </span>
            <div className='w-full h-full flex items-center justify-around'>
                <span className='text-2x1 xl:text-4x1 2x1:text-5x1 flex items-center'>
                    ${price}
                    <span className='text-lg xl:text-x1 2x1:text-2x1 text-neutral-400 m-2'>
                        {currency}
                    </span>
                </span>
                <span className={`text-lg xl:text-x1 2x1:text-2x1 ${change > 0 ? "text-lime-500" : "text-red-500"}`}>
                    ${change} <span>({changePercent}%)</span>
                </span>
            </div>
        </Card>

    )
}

export default Overview