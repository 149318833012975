import React, { useContext, useEffect, useState } from "react";
import Card from "./Card";
import ReactApexChart from "react-apexcharts";
import ThemeContext from "../../context/ThemeContext";
import StockContext from "../../context/StockContext";
import { fetchHistoricalData } from "../../api/stock-api";

const Chart = () => {
  const { darkMode } = useContext(ThemeContext);
  const { stockSymbol } = useContext(StockContext);
  const [data, setData] = useState([]);

  const formatData = (data) => {
    const seriesKey = `Monthly Time Series`;
    const timeSeriesData = data[seriesKey];

    const chartData = Object.entries(timeSeriesData)
      .map(([date, values]) => ({
        x: new Date(date).getTime(),
        y: [
          parseFloat(values["1. open"]),
          parseFloat(values["2. high"]),
          parseFloat(values["3. low"]),
          parseFloat(values["4. close"]),
        ],
      }))
      .sort((a, b) => a.x - b.x);

    return chartData;
  };

  useEffect(() => {
    let isMounted = true;

    const updateChartData = async () => {
      try {
        const result = await fetchHistoricalData(stockSymbol);
        
        if (isMounted) {
          setData(formatData(result));
        }
      } catch (error) {
        setData([]);
        console.error(error);
      }
    };

    updateChartData();

    return () => {
      isMounted = false;
    };
  }, [stockSymbol]); // Adicionado array de dependências vazio para executar apenas uma vez

  const options = {
    chart: {
      type: "candlestick",
      height: "100%",
    },
    title: {
      text: `${stockSymbol} Chart.`,
      align: "left",
      style: {
        color: darkMode ? "#ffffff" : "#000000",
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
        style: {
          color: darkMode ? "#000000" : "#ffffff",
        },
      },
    },
  };

  return (
    <Card >
      <div className="w-full h-full">
        <ReactApexChart options={options} series={[{ data }]} type="candlestick" height="100%" />
      </div>
    </Card>
  );
};

export default Chart;