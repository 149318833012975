const basePath = "https://finnhub.io/api/v1";




export const searchSymbol = async (query, apiKey) => {
  const url = `https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=${query}&apikey=${apiKey}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

   
    if (data && data.Information) {
      console.warn('Information from Alpha Vantage API:', data.Information);
      return []; 
    }

    if (data && data.bestMatches) {
      return data.bestMatches;
    } else {
      console.error('Unexpected response structure:', data);
      throw new Error('Unexpected response structure');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


export const fetchStockDetails = async (stockSymbol) => {
  const url = `${basePath}/stock/profile2?symbol=${stockSymbol}&token=${process.env.REACT_APP_API_KEY}`;
  const response = await fetch(url);

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  return await response.json();
};


export const fetchQuote = async (stockSymbol) => {
  const url = `${basePath}/quote?symbol=${stockSymbol}&token=${process.env.REACT_APP_API_KEY}`;
  const response = await fetch(url);

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  return await response.json();
}; 


export const fetchHistoricalData = async (stockSymbol, interval) => {
  const apiKey = process.env.REACT_APP_API_KEY1;
  const functionType = getFunctionType(interval);
  const url = `https://www.alphavantage.co/query?function=${functionType}&symbol=${stockSymbol}&apikey=${apiKey}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching historical data:", error);
    throw error;
  }
};

const getFunctionType = (interval) => {

  const intervalMapping = {
    "1D": "TIME_SERIES_INTRADAY",
    "1W": "TIME_SERIES_WEEKLY",
    "1M": "TIME_SERIES_MONTHLY", 
  };

  return intervalMapping[interval] || "TIME_SERIES_MONTHLY"; 
};