import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from "./components/StockInfo/Dashboard";
import HomePage from "./components/HomePage/HomePage";
import Portefolio from './components/Portefolio/Portefolio';
import { useState } from 'react';
import ThemeContext from './context/ThemeContext';
import StockContext from './context/StockContext';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [stockSymbol, setStockSymbol] = useState("DIS");

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <StockContext.Provider value={{ stockSymbol, setStockSymbol }}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/portfolio' element={<Portefolio />} />
          </Routes>
        </Router>
      </StockContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;