import React, { useContext } from 'react'
import { MoonIcon } from '@heroicons/react/solid'
import ThemeContext from '../../context/ThemeContext'


const PageTheme = () => {
  const {darkMode, setDarkMode} = useContext(ThemeContext);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };
  return (
    <button className={ `rounded-full border-1 border-neutral-400 p-2 absolute right-3 xl:right-32 shadow-lg ${ darkMode ? "shadow-gray-800" : null}`} onClick={toggleTheme}>
        <MoonIcon className={`h-8 w-8 cursor-pointer stroke-1 fill-none  ${darkMode ? "fill-yellow-400 stroke-yellow-400" : "fill-none stroke-neutral-400" }`}/>
    </button>
  )
}

export default PageTheme




