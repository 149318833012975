import React, { useContext, useState } from 'react';
import ThemeContext from '../../context/ThemeContext';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { darkMode } = useContext(ThemeContext);

  return (
    <nav className={`p-2 text-center ${darkMode ? "text-white" : "text-black" }`}>
      <div className="hidden sm:inline-block">
        <Link to="/" className="inline-block py-2 px-4">HOME</Link>
        <Link to="/dashboard" className="inline-block py-2 px-4">DASHBOARD</Link>
        <Link to="/portfolio" className="inline-block py-2 px-4">PORTFOLIO</Link>
      </div>

      <div className="sm:hidden">
        <button 
          className="block text-2xl focus:outline-none" 
          onClick={() => setShowMenu(!showMenu)} 
        >
          &#9776;
        </button>

        {showMenu && (
          <div className="mt-2">
            <Link to="/" className="block py-2 px-4 bg-gray-200 bg-opacity-50 hover:bg-opacity-75 rounded">HOME</Link>
            <Link to="/dashboard" className="block py-2 px-4 bg-gray-200 bg-opacity-50 hover:bg-opacity-75 rounded">DASHBOARD</Link>
            <Link to="/portfolio" className="block py-2 px-4 bg-gray-200 bg-opacity-50 hover:bg-opacity-75 rounded">PORTFOLIO</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;